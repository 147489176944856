import axios from "axios";
import { FILE_UPLOAD_URL } from "@/utils/constants";

// redirect courier partner to screen by his/her right or status
export const redirectPartnerByStatus = ({ payment, status }) => {
  switch (true) {
    case status && payment:
      return "Dashboard";
    case status && !payment:
      return "PaymentForm";
    case !status:
      return "RegistrationForm";
  }
};

export const _toggleTab = (_this, _value) => {
  _this.selectedTab = _value;
};

// uploading file to server and returning file url
export const uploadImageFile = async (_blob) => {
  const formData = new FormData();
  formData.append("image", _blob, _blob.name ? _blob.name : "sign");
  formData.append("isResizeRequired", true);
  const data = await axios
    .post(FILE_UPLOAD_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch(() => {
      console.log("error");
    });
  return data.data.mediaVariantDTOs[0].url;
};

export const getMessageByType = (_type, _this) => {
  let _message = _this.$t("_partnerDefaultStatus");
  switch (_type) {
    case "kyc-pending":
      _message = _this.$t("_partnerKycPendingStatus");
      break;
    case "registered":
      _message = _this.$t("_partnerRegisteredStatus");
      break;
    default:
      _message = _this.$t("_partnerDefaultStatus");
      break;
  }
  return _message;
};

// converting base64 to blob
export const b64toBlob = (dataURI, type) => {
  let byteString = atob(dataURI.split(",")[1]);
  const _ab = new ArrayBuffer(byteString.length);
  var _ia = new Uint8Array(_ab);
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([_ab], { type: type });
};

export const getBase64Url = (file) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  var base64 = new Promise(function (resolve, reject) {
    reader.onload = (event) => {
      if (event.target)
        resolve(event.target.result);
      else
        reject(new Error('fail'))
    };
  });
  return base64;
}
